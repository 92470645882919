'use strict';


/**
 * This method loads countdown timer in upsell Modal
 * @param {time} - i - time in second to start countdown timer
 */
function countDown(i) {

    var timer = setInterval(function() {
      var minutes = parseInt(i / 60, 10);
      var seconds = parseInt(i % 60, 10);
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      console.log('time',minutes+':'+seconds);
      var upsellProductAddedToCart = window.sessionStorage.getItem('upsellProductAddedToCart');
      //   Check if upsell product is already added then remove timer
      if (upsellProductAddedToCart && window.sessionStorage.getItem('upsellProductAddedToCart') == 'true') {
        $("div.wrapper").hide();
        $("div.timer-wrapper").text('');
      } else {
        // update time left in DOM here
        $("div.wrapper").show();
        $("div.timer-wrapper").text("0:" + minutes + ":" + seconds);
      }

      if ((i--) > 0) {
        window.sessionStorage.setItem('COUNTER_KEY', i);
        window.sessionStorage.setItem('UpsellCounterStarted', true);
      } else {
        window.sessionStorage.removeItem('COUNTER_KEY');
        clearInterval(timer);
        // Remove upsell promotion here as time elapsed
        removeUpsellPromotion();
        window.sessionStorage.removeItem('UpsellCounterStarted');
      }
    }, 1000);
}

/**
 * This method will be called when time elapsed and will make ajax call to remove promo
 */
function removeUpsellPromotion() {
    var $modal = $('#quickViewModal');
    var URL = window.sessionStorage.getItem('Upsell_Url');
    window.sessionStorage.setItem('upsellPromoTimeElapsed', true);
    if ($modal.length){
        var quickView = require('../product/quickView');
        quickView.fillModalElement(URL);
        if ($('div.upsell-promo-timer-wrapper').length) {
            $('div.upsell-promo-timer-wrapper').hide();
        }
    }
}

module.exports = {
    countDown: countDown
}