'use strict';

var integrations = require('integrations/main');

integrations.baseFiles.base = require('./product/base');
integrations.baseFiles.slider = require('./components/slider').init;
//PAP-647
integrations.baseFiles.search = require('./components/search'),
//PAP-1119
//PAP-4762- Site Performance
document.addEventListener('DOMContentLoaded', function() {
    integrations.baseFiles.quickView = require('./product/quickView')
});

//PAP-939
integrations.baseFiles.minicart = require('./components/miniCart'),
integrations.baseFiles.footer = require('./components/footer');

//PAP-4762- Site Performance
document.addEventListener('DOMContentLoaded', function() {
    integrations.baseFiles.backToTop = require('./components/backToTop');
});

integrations.baseFiles.consentTracking = require('./components/consentTracking');
integrations.baseFiles.cart = require('./cart');
integrations.baseFiles.menu = require('./components/menu');
//PAP-4674
integrations.baseFiles.flyout = require('./components/flyout');
//PAP-876
integrations.baseFiles.ada = require('./components/ada');
//PAP-4761 - Optimize JavaScript: Ensure efficient code
document.addEventListener('DOMContentLoaded', function() {
//PAP-802
    integrations.baseFiles.countdown = require('./thirdParty/jquery.countdown.min');
    integrations.baseFiles.lazy = require('./thirdParty/jquery.lazy.min');
    integrations.baseFiles.customCounter = require('./components/customCounter');
});
//PAP-1118
integrations.baseFiles.clientSideValidation = require('./components/clientSideValidation');
integrations.baseFiles.productCustomizer = require('./product/productCustomizer');

//PAP-4762- Site Performance
document.addEventListener('DOMContentLoaded', function() {
    integrations.baseFiles.einsteinRecs = require('./components/einsteinRecs').init;
    integrations.baseFiles.wishlist = require('./wishlist/wishlist').init;
    if ($('#convergeTrackingData').val() && typeof cvg != 'undefined') {
        cvg(JSON.parse($('#convergeTrackingData').val()));
    }
});
//Meta Conversion Tracking
//Since we have implemented Converge,so commenting out the old meta integration code.
//integrations.baseFiles.metaConversion = require('./metaConversion/metaConversion').trackConversion();

module.exports = integrations;
