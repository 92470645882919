'use strict';

var baseWishlist = require('core/wishlist/wishlist');

/**
 * Makes a call to the server to report the event of adding an item to the cart
 *
 * @param {string | boolean} url - a string representing the end point to hit so that the event can be recorded, or false
 */
function miniCartReportingUrl(url) {
    if (url) {
        $.ajax({
            url: url,
            method: 'GET',
            success: function () {
                // reporting urls hit on the server
            },
            error: function () {
                // no reporting urls hit on the server
            }
        });
    }
}

baseWishlist.displayMessage = function(data) {
    $.spinner().stop();
    var status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-to-wishlist-messages').length === 0) {
        $('body').append('<div class="add-to-wishlist-messages"></div>');
    }
    //PAP-4085 start - update wishlist popup design
    var wishlistLink = $('.wishlist-icon a').attr('href');
    $('.add-to-wishlist-messages').append('<div class="alert text-left add-to-wishlist-alert text-left ' + status + '"><div>' + data.msg + '</div><div class="wishlist-product-name">'+ data.wishlistProductName +'</div><a href="' + wishlistLink + '" class="view-wishlist btn btn-primary w-100">View Wishlist</a><button class="btn close" data-dismiss="modal" aria-label="Close"><span class="icon-close"></span></button></div>');

    $('body').on('click', '.add-to-wishlist-messages .btn.close', function (e) {
        $('.add-to-wishlist-messages').remove();
    });
    //PAP-4085 end
    setTimeout(function () {
        $('.add-to-wishlist-messages').remove();
    }, 5000);
}

/**
 * handles add to cart on click for add to cart button on wishlist-show and others
 */
baseWishlist.addToCartFromWishlist = function() {
    $('body').on('click', '.add-to-cart', function () {
        var pid;
        var addToCartUrl;
        var pidsQty;

        $('body').trigger('product:beforeAddToCart', this);

        pid = $(this).data('pid');
        addToCartUrl = $(this).data('url');
        pidsQty = parseInt($(this).closest('.product-info').find('.quantity').val(), 10);

        var form = {
            pid: pid,
            quantity: pidsQty
        };

        if (pid && pid.toString().includes('MSMM_')) {
            var pidArr = pid.split('_');
            var pairedID;
            if (pidArr.length > 2) {
                pid = pidArr[1];
                pairedID= pidArr[2];
            }
            form.pairedAction = true;
            form.pid = pid;
            form.pairedParent = pid;
            form.pairedChild = pairedID;
        }

        if ($(this).data('option')) {
            form.options = JSON.stringify($(this).data('option'));
        }

        $(this).trigger('updateAddToCartFormData', form);
        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    baseWishlist.handlePostCartAdd(data);
                    $('body').trigger('product:afterAddToCart', data);
                    $.spinner().stop();
                    miniCartReportingUrl(data.reportingURL, data.error);
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
}

module.exports = {
    baseWishlist: baseWishlist,
    init: function() {
        baseWishlist.addToCartFromWishlist();
        baseWishlist.toggleWishlist();
        baseWishlist.cartUpdate();
        baseWishlist.updateWishlistLinkData();
        baseWishlist.copyWishlistLink();
        baseWishlist.updateQuickView();
        baseWishlist.viewProductViaEdit();
        baseWishlist.focusEditWishlistProductModal();
        baseWishlist.onClosingEditWishlistProductModal();
        baseWishlist.trapEditWishlistProductModalFocus();
        baseWishlist.updateWishlistItem();
        baseWishlist.toggleWishlistStatus();
        baseWishlist.toggleWishlistItemStatus();
        baseWishlist.removeFromWishlist();
        baseWishlist.moreWLItems();
    }
}
