'use strict';

module.exports = () => {

    $('body').on('keydown', '.modal.show', function (e) {
        $('.modal.show').on('keydown', function(e) {
            var isTabPressed = e.key === 'Tab' || e.keyCode === 9;
            if (!isTabPressed) {
                return;
            }
            var focusableContent = $('.show.modal').find('button:visible, [href]:visible, input:visible, select:visible, textarea:visible, [tabindex]:not([tabindex="-1"])');
            var firstFocusableElement = focusableContent[0];
            var lastVisibleFocusableElement = focusableContent[focusableContent.length - 1]; 
            if (document.activeElement === lastVisibleFocusableElement) { 
                e.preventDefault();
                firstFocusableElement.focus();
            }
        });
    });


}