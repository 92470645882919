'use strict';

module.exports = function (element) {
    var position = element && element.length ? element.offset().top : 0;
    $('html, body').animate({
        scrollTop: position
    }, 500);
    if (!element) {
        $('.logo-home').focus();
    }
};
function TabSlidersScroll() {
    $('.tabpicker li').on('click',function(){
        var wrapperScroll= $(this).closest('.tabpicker');
        var maxScrollLeft= $(wrapperScroll).scrollLeft('.tabpicker').prop('scrollWidth') - $(".tabpicker").width();
        var left = $(this).offset().left + 120;
        var width = $(wrapperScroll).width();
        var diff = left - width/2;
        $(wrapperScroll).scrollLeft($(wrapperScroll).scrollLeft()+diff);
        $('.tabpicker  li:last-child').off("click");
    });
}
TabSlidersScroll();
